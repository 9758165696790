import React from "react";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import { CustomLink } from "../../utils/CustomLink";
import "./text.scss";

export const Text = ({ slice }) => {

  const content = slice.primary?.content2 ? (
    <>
      <div>
        <RichText
          render={slice.primary.content.raw}
          serializeHyperlink={CustomLink}
        />
      </div>
      <div>
        <RichText
          render={slice.primary.content2.raw}
          serializeHyperlink={CustomLink}
        />
      </div>
    </>
  ) : (
    <RichText
      render={slice.primary.content.raw}
      serializeHyperlink={CustomLink}
    />
  );

  return slice?.primary ? (
    <section
      className={`slice-text ${
        slice.primary.hasOwnProperty("bgcolor")
          ? "".concat(slice.primary.bgcolor).replace(/\s/g, "")
          : ""
      } fullwidth`}
    >
      <div
        className={`slice-text__inner ${
          slice.primary?.content2 ? "slice-text__2-col" : ""
        }`}
      >
        {content}
      </div>
    </section>
  ) : null;
};

export const query = graphql`
  fragment PageDataBodyText on PrismicPageDataBodyText {
    primary {
      bgcolor
      content {
        raw
      }
      content2 {
        raw
      }
    }
  }
  fragment PageDataBodyText1 on PrismicPageDataBodyText1 {
    primary {
      bgcolor
      content {
        raw
      }
    }
  }
  fragment HomepageDataBodyText on PrismicHomepageDataBodyText {
    primary {
      content {
        raw
      }
    }
  }
`;

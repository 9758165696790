import React, { useRef, useEffect, useState } from "react";

export function useOnScreen(ref, callback = () => {}) {
  const [isIntersecting, setIntersecting] = useState(false);
  const observer = useRef();

  useEffect(() => {
    observer.current = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    });
    observer.current.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.current.disconnect();
    };
  }, [observer, ref]);

  useEffect(() => {
    callback(isIntersecting);
  }, [isIntersecting, callback]);
}

/**
 * Component that alerts if you click outside of it
 */
export default function IsInView(props) {
  const wrapperRef = useRef(null);
  useOnScreen(wrapperRef, props.callback);

  return <div ref={wrapperRef}>{props.children}</div>;
}

import React from "react";

const Share = ({ fill = "#FFCEDD" }) => {
  return (
    <svg
      className="icon--share"
      width="32"
      height="36"
      viewBox="0 0 32 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.758 0C22.412 0 19.6697 2.7025 19.6697 5.99992C19.6697 6.63812 19.7722 7.26733 19.9624 7.84604L9.90797 13.3558C8.85972 12.5175 7.53343 12.0001 6.08822 12.0001C2.74228 12.0001 0 14.7026 0 18C0 21.2974 2.74228 23.9999 6.08822 23.9999C7.53343 23.9999 8.85977 23.4969 9.90797 22.6586L19.977 28.1684C19.7849 28.7489 19.6696 29.36 19.6696 30.0001C19.6696 33.2975 22.4119 36 25.7578 36C29.1038 36 31.8461 33.2975 31.8461 30.0001C31.8461 26.7027 29.1038 24.0002 25.7578 24.0002C24.0766 24.0002 22.5454 24.6925 21.4404 25.7886L11.6496 20.4233C11.9844 19.6823 12.1765 18.8584 12.1765 18.0003C12.1765 17.1367 11.9881 16.3218 11.6496 15.5772L21.4259 10.2119C22.5327 11.317 24.0694 12.0003 25.7579 12.0003C29.1039 12.0003 31.8462 9.29784 31.8462 6.00042C31.8462 2.70301 29.1039 0.000507494 25.7579 0.000507494L25.758 0ZM25.758 2.76919C27.5855 2.76919 29.0362 4.19888 29.0362 5.99992C29.0362 7.80095 27.5855 9.23064 25.758 9.23064C23.9304 9.23064 22.4797 7.80095 22.4797 5.99992C22.4797 4.19888 23.9304 2.76919 25.758 2.76919ZM6.08832 14.769C7.91586 14.769 9.36659 16.1987 9.36659 17.9997C9.36659 19.8008 7.91586 21.2305 6.08832 21.2305C4.26077 21.2305 2.81004 19.8008 2.81004 17.9997C2.81004 16.1987 4.26077 14.769 6.08832 14.769ZM25.758 26.7689C27.5855 26.7689 29.0362 28.1985 29.0362 29.9996C29.0362 31.8006 27.5855 33.2303 25.758 33.2303C23.9304 33.2303 22.4797 31.8006 22.4797 29.9996C22.4797 28.1985 23.9304 26.7689 25.758 26.7689Z"
        fill={fill}
      />
    </svg>
  );
};

export default Share;

import React from "react";

const LinkedInShare = ({ fill = "#FF5CCD" }) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon--linkedin-share"
    >
      <line className="icon-circle" x1="40" y1="40" x2="40" y2="40" stroke={fill} stroke-linecap="round" stroke-linejoin="round" stroke-width="66.6666" />
      <path
        d="M50.6945 21.6665H29.3056C25.0874 21.6665 21.6667 25.0872 21.6667 29.3054V50.6943C21.6667 54.9125 25.0874 58.3332 29.3056 58.3332H50.6945C54.9142 58.3332 58.3334 54.9125 58.3334 50.6943V29.3054C58.3334 25.0872 54.9142 21.6665 50.6945 21.6665ZM33.8889 50.6943H29.3056V33.8887H33.8889V50.6943ZM31.5972 31.9515C30.1214 31.9515 28.9236 30.7446 28.9236 29.2565C28.9236 27.7684 30.1214 26.5615 31.5972 26.5615C33.0731 26.5615 34.2709 27.7684 34.2709 29.2565C34.2709 30.7446 33.0746 31.9515 31.5972 31.9515ZM52.2222 50.6943H47.6389V42.1326C47.6389 36.9871 41.5278 37.3766 41.5278 42.1326V50.6943H36.9445V33.8887H41.5278V36.5853C43.6606 32.6344 52.2222 32.3426 52.2222 40.368V50.6943Z"
        fill="#700650"
      />
    </svg>
  );
};

export default LinkedInShare;

import React from "react";

const TwitterShare = ({ fill = "#FF5CCD" }) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon--twitter-share"
    >
      <line
        className="icon-circle"
        x1="40"
        y1="40"
        x2="40"
        y2="40"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="66.6666"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.6667 29.8732C55.4412 30.4176 54.1224 30.7842 52.7391 30.9498C54.1513 30.1032 55.2356 28.7633 55.7456 27.1667C54.4257 27.9511 52.9602 28.5188 51.4037 28.8266C50.156 27.4978 48.3772 26.6667 46.4118 26.6667C42.6354 26.6667 39.5734 29.7288 39.5734 33.5052C39.5734 34.0407 39.6345 34.5629 39.7511 35.064C34.0671 34.7795 29.0285 32.0564 25.6554 27.9189C25.0665 28.9288 24.7299 30.1032 24.7299 31.3564C24.7299 33.7285 25.9354 35.8217 27.7719 37.0483C26.6498 37.0127 25.5965 36.705 24.6732 36.1928V36.2783C24.6732 39.5926 27.032 42.3557 30.1584 42.9857C29.5851 43.1412 28.9807 43.2256 28.3574 43.2256C27.9164 43.2256 27.4875 43.1823 27.0697 43.1023C27.9397 45.8188 30.4662 47.7965 33.4582 47.852C31.1184 49.6852 28.1697 50.7796 24.9654 50.7796C24.4121 50.7796 23.8677 50.7474 23.3333 50.6829C26.3598 52.6228 29.954 53.7561 33.816 53.7561C46.394 53.7561 53.2724 43.3356 53.2724 34.2996C53.2724 34.0029 53.2669 33.7074 53.2524 33.4152C54.5901 32.4486 55.75 31.2442 56.6667 29.8732Z"
        fill="#700650"
      />
    </svg>
  );
};

export default TwitterShare;

import React, { useState } from "react";
import { RichText } from "prismic-reactjs";
import { graphql, useStaticQuery } from "gatsby";
import { spliceArrayN } from "../../utils/SpliceArrayN";
import { Card } from "../../components/Card/Card.js";
import { Swiper, SwiperSlide } from "swiper/react";
import { Dropdown } from "../../components/Dropdown/Dropdown.js";
// Import Swiper styles
import "swiper/css";
import "./jury.scss";
import { isNullishCoalesce } from "typescript";

export const PlaceholderJury = ({ slice }) => {
  const queryData = useStaticQuery(graphql`
    query allJuryQuery {
      allPrismicJury {
        nodes {
          data {
            name
            card_color
            image {
              url
              alt
            }
            enroled_in {
              raw
            }
            country_of_origin
            job_position
            button_link_external {
              url
              target
            }
          }
        }
      }
    }
  `);

  const numOfSteps = 4;
  const [numberOfRows, setNumberOfRows] = useState(4);
  const [allData] = useState([...queryData.allPrismicJury.nodes]);
  const [allJuryMembers, setAllJuryMembers] = useState(
    spliceArrayN([...queryData.allPrismicJury.nodes], 4)
  );

  const [orderDir, setOrderDir] = useState("Filter by");
  const [selection, setSelection] = useState("Filter by");
  const [updateTs, setUpdateTs] = useState(0);

  const dropdownData = [
    [
      { value: "Filter by", id: "Filter by" },
      { value: "Name", id: "0" },
      { value: "Academic Major", id: "1" },
      { value: "Originally from", id: "2" },
      { value: "Enrolled at", id: "3" },
    ],
    [
      {
        "Filter by": {
          values: [{ value: "Sort by", id: "0-default" }],
          id: "0",
        },
        0: {
          values: [
            { value: "Sort by", id: "0-default" },
            { value: "a-f", id: "0-0" },
            { value: "g-l", id: "0-1" },
            { value: "m-r", id: "0-2" },
            { value: "s-z", id: "0-3" },
          ],
          id: "0",
        },
        1: {
          values: [
            { value: "Sort by", id: "1-default" },
            ...[
              ...new Set([
                ...queryData.allPrismicJury.nodes.filter((v) => v.data?.job_position).map((v) =>
                  v.data?.job_position
                ),
              ]),
            ]
              .sort()
              .map((val, idx) => {
                return { value: val, id: `1-${idx}` };
              }),
          ],
          id: "1",
        },
        2: {
          values: [
            { value: "Sort by", id: "2-default" },
            { value: "a-f", id: "2-0" },
            { value: "g-l", id: "2-1" },
            { value: "m-r", id: "2-2" },
            { value: "s-z", id: "2-3" },
          ],
          id: "2",
        },
        3: {
          values: [
            { value: "Sort by", id: "3-default" },
            ...[
              ...new Set([
                ...queryData.allPrismicJury.nodes.map(
                  (v) => v.data?.enroled_in?.raw[0]?.text
                ),
              ]),
            ]
              .sort()
              .map((val, idx) => {
                return { value: val, id: `3-${idx}` };
              }),
          ],
          id: "3",
        },
      },
    ],
  ];

  const sortJuryMembers = (id, sort) => {
    let tmpArr = JSON.parse(JSON.stringify(allData));

    const sortByName = (arr) => {
      return arr
        .sort((a, b) =>
          a.data.name > b.data.name ? 1 : b.data.name > a.data.name ? -1 : 0
        )
        .reverse();
    };

    const sortByOrigin = (arr) => {
      return arr
        .sort((a, b) =>
          a.data.country_of_origin > b.data.country_of_origin
            ? 1
            : b.data.country_of_origin > a.data.country_of_origin
            ? -1
            : 0
        )
        .reverse();
    };

    const filterAlphabetically = (arr, attr, sortVal) => {
      const abc = "abcdefghijklmnopqrstuvwxyz";
      const firstAbcIndex = abc.indexOf(sortVal.split("-")[0]);
      const secondAbcIndex = abc.indexOf(sortVal.split("-")[1]);

      return arr.filter((val) => {
        for (let i = firstAbcIndex; i < secondAbcIndex + 1; i++) {
          if (val.data[attr].trim().toLowerCase().charAt(0) === abc[i]) {
            return val;
          }
        }
      });
    };

    const filterByValue = (arr, attr, value) => {
      if (attr === "enroled_in") {
        return arr.filter(
          (val) => val?.data?.enroled_in?.raw[0]?.text === value
        );
      } else {
        return arr.filter((val) => val?.data[attr] === value);
      }
    };

    switch (!sort ? id : selection) {
      case "0":
        tmpArr = sortByName(tmpArr);
        break;
      case "1":
        tmpArr.sort((a, b) => {
          const aJobPos = a.data.job_position
            ? a.data.job_position.trim().toLowerCase()
            : "zzzzzz";
          const bJobPos = b.data.job_position
            ? b.data.job_position.trim().toLowerCase()
            : "zzzzzz";

          if (aJobPos < bJobPos) {
            return -1;
          }
          if (aJobPos > bJobPos) {
            return 1;
          }

          return 0;
        }).reverse();

        break;
      case "2":
        tmpArr = sortByOrigin(tmpArr);
        break;
      case "3":
        tmpArr.sort((a, b) => {
          const aEnrolledAt = a.data.enroled_in.raw[0].text
            .trim()
            .replace("M.AD", "")
            .toLowerCase();
          const bEnrolledAt = b.data.enroled_in.raw[0].text
            .trim()
            .replace("M.AD", "")
            .toLowerCase();
          return aEnrolledAt > bEnrolledAt
            ? 1
            : bEnrolledAt > aEnrolledAt
            ? -1
            : 0;
        }).reverse();
        break;
    }

    if (sort) {
      const sortVal =
        dropdownData[1][0][selection].values[
          parseInt(id.replace(`${selection}-`, "")) + 1
        ].value;
      switch (selection) {
        case "0":
          tmpArr = filterAlphabetically(tmpArr, "name", sortVal);
          break;
        case "1":
          tmpArr = filterByValue(sortByName(tmpArr), "job_position", sortVal);
          break;
        case "2":
          tmpArr = filterAlphabetically(tmpArr, "country_of_origin", sortVal);
          break;
        case "3":
          tmpArr = filterByValue(sortByName(tmpArr), "enroled_in", sortVal);
          break;
      }
    }

    return setAllJuryMembers(spliceArrayN(tmpArr.reverse(), 4));
  };

  return (
    <>
      <section
        className={`typo__section fullwidth slice-jury ${updateTs}`}
        id="jury"
      >
        <div className="typo__wrapper">
          <h2 className="typo__title">
            {RichText.asText(slice.primary.headline.raw)}
          </h2>
          <p className="typo__text">
            {RichText.asText(slice.primary.text.raw)}
          </p>
        </div>
        <div className="slice-placeholder-project__dropdown-wrapper">
          <Dropdown
            direction="left"
            currentActive={selection}
            itemsArr={dropdownData[0]}
            callback={(id) => {
              sortJuryMembers(id, false);
              setSelection(id);
            }}
            size={320}
          />
          <Dropdown
            direction="right"
            currentActive={"Sort by"}
            itemsArr={dropdownData[1][0][selection].values}
            callback={(id) => {
              sortJuryMembers(id, true);
              setOrderDir(id);
            }}
            size={320}
          />
        </div>
        <div className="slice-jury__card-wrapper">
          {allJuryMembers &&
            allJuryMembers.map((allJuryMembers, rowIdx) => {
              if (rowIdx < numberOfRows) {
                return (
                  <div
                    className="section"
                    key={"juryMemberSection-".concat(rowIdx)}
                  >
                    <div className="slice-jury__card-wrapper--swiper">
                      <Swiper
                        spaceBetween={30}
                        slidesPerView={"auto"}
                        autoHeight={true}
                        centeredSlides={true}
                        onSlideChange={(swiper) => {
                          swiper.slides[swiper.previousIndex]
                            .querySelector(".component-card")
                            .classList.remove("active-card");
                        }}
                        breakpoints={{
                          768: {
                            centeredSlides: false,
                            spaceBetween: 48,
                          },
                        }}
                      >
                        {allJuryMembers.map((juryMember, colIdx) => {
                          return (
                            <SwiperSlide key={`SwiperSlideJury-${colIdx}`}>
                              <Card
                                cardType="Jury"
                                {...juryMember.data}
                                delay={(rowIdx + colIdx) * 100}
                                key={juryMember.data.name + "idx"}
                              />
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          {allJuryMembers.length > numberOfRows ? (
            <div
              className="slice-jury__load-more"
              onClick={() => {
                const newNumberOfRows =
                  numberOfRows + numOfSteps < allJuryMembers.length
                    ? numberOfRows + numOfSteps
                    : numberOfRows + (allJuryMembers.length - numberOfRows);

                setNumberOfRows(newNumberOfRows);
              }}
            >
              LOAD MORE ↓
            </div>
          ) : null}
        </div>
      </section>
    </>
  );
};

// export const JuryCard = ({
//   name,
//   image,
//   enroled_in,
//   country_of_origin,
//   button_text,
//   button_link_external,
// }) => {
//   return (
//     <div>
//       <span>Jury</span>
//       <div>
//         <div
//           style={{
//             backgroundImage: `url(${
//               image?.url
//                 ? image.url
//                 : "https://via.placeholder.com/1920x1080/eee?text=16:9"
//             })`,
//           }}
//         ></div>
//       </div>
//       <div>{name}</div>
//       <div>
//         <p>originally from</p>
//         <p>{country_of_origin}</p>
//       </div>
//       <div>
//         <p>enroled at</p>
//         <p>{enroled_in}</p>
//       </div>
//       <div>
//         <Link to={button_link_external.url} className="section-button">
//           {button_text}
//         </Link>
//       </div>
//     </div>
//   );
// };

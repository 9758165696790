import React, { useState, useEffect, useCallback } from "react";
import { getCookie } from "../../utils/getCookie.js";
import { setCookie } from "../../utils/setCookie.js";

import { useStaticQuery, graphql } from "gatsby";

const cookieName = "consent";

export const ConsentContext = React.createContext();

export const ConsentProvider = ({ hideConsent, children }, props) => {
  const query = useStaticQuery(
    graphql`
      query {
        prismicConsentmanager {
          data {
            body {
              ...ConsentManagerCategories
            }
          }
        }
      }
    `
  );

  const [showBanner, setShowBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [cookieValues, setCookieValues] = useState({});

  // const checkIfAnalyticsIsActive = useCallback((attributes) => {
  //   if (typeof window === "undefined" || !window.document) {
  //   } else {
  //     Object.keys(attributes).forEach((attrKey) => {
  //       const attrValue = attributes[attrKey];
  //       if (
  //         attrKey === "analytics" &&
  //         window &&
  //         window.gatsbyPluginGDPRCookiesOptions
  //       ) {
  //         setCookie("google-analytics", attrValue, 365);
  //         window[
  //           `ga-disable-${window.gatsbyPluginGDPRCookiesOptions.googleAnalytics.trackingId}`
  //         ] = !attrValue;
  //       }
  //     });
  //   }
  // }, []);

  useEffect(() => {
    const activeCookie = getCookie(cookieName);

    const activeCookieAttributes = {};
    activeCookie &&
      activeCookie.split(",").forEach((attr) => {
        const values = attr.split("=");
        activeCookieAttributes[values[0]] = values[1] === "true";
      });

    const initialCookieValues = {};
    query.prismicConsentmanager.data.body.forEach((attr) => {
      if (attr && attr.items) {
        attr.items.forEach((item) => {
          initialCookieValues[item.identifier] = item.always_on;
        });
      }
    });

    const initStateCookieValues =
      Object.keys(activeCookieAttributes).length > 0
        ? activeCookieAttributes
        : initialCookieValues;

    setShowBanner(!activeCookie && !hideConsent);
    setShowModal(false);
    setCookieValues(initStateCookieValues);
    // checkIfAnalyticsIsActive(initStateCookieValues);
  }, [
    // checkIfAnalyticsIsActive,
    query.prismicConsentmanager.data.body,
    hideConsent,
  ]);

  const setConsentCookie = (attributes) => {
    setShowBanner(false);
    setShowModal(false);
    setCookieValues(attributes);

    const cookieString = Object.keys(attributes).map((attrKey) => {
      const attrValue = attributes[attrKey];
      return `${attrKey}=${attrValue}`;
    });

    // checkIfAnalyticsIsActive(attributes);
    setCookie(cookieName, cookieString, 365);
  };

  const revertSettingsView = () => {
    const currentState = showBanner;
    setShowBanner(!currentState);
    setShowModal(currentState);
  };

  const showChangeSettings = () => {
    setShowBanner(false);
    setShowModal("static");
  };

  const value = {
    showBanner,
    setShowBanner,
    showModal,
    setShowModal,
    cookieValues,
    setConsentCookie,
    revertSettingsView,
    showChangeSettings,
  };

  return (
    <ConsentContext.Provider value={value}>
      {
        <div
          style={
            !showModal && !showBanner
              ? null
              : { overflow: "hidden", width: "100vw", height: "100vh" }
          }
        >
          {children}
        </div>
      }
    </ConsentContext.Provider>
  );
};

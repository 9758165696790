import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { SliceZone } from "../SliceZone.js";

export const ConsentManager = () => {
  const query = useStaticQuery(
    graphql`
      query {
        prismicConsentmanager {
          data {
            body {
              ... on PrismicSliceType {
                slice_type
              }
              ...ConsentManagerDataBodyModal
              ...ConsentManagerDataBodyBanner
            }
          }
        }
      }
    `
  );

  return <SliceZone noMain={true} sliceZone={query.prismicConsentmanager.data.body} />;
};

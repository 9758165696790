/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function Seo({
  description,
  lang,
  meta,
  title,
  pageUrl,
  schema,
  socialCards = [],
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
          }
        }
      }
    `
  );

  let general = false;
  let twitter = false;

  socialCards.forEach((socialCard) => {
    switch (socialCard.slice_type) {
      case "general_card":
        general = socialCard?.primary;
        break;
      case "twitter_card":
        twitter = socialCard?.primary;
        break;
      default:
        break;
    }
  });

  const metaDescription =
    description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  pageUrl = pageUrl
    ? site.siteMetadata.url.concat(pageUrl)
    : site.siteMetadata.url;
  const language = lang ? lang : "en";

  return (
    <Helmet
      htmlAttributes={{
        language,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:description`,
          content: general?.description?.text || metaDescription,
        },
        {
          property: `og:title`,
          content: general?.title?.text || title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: pageUrl,
        },
        {
          property: `og:image`,
          content: general?.image?.url || "",
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: twitter?.title?.text || title,
        },
        {
          name: `twitter:description`,
          content: twitter?.description?.text || metaDescription,
        },
        {
          name: `twitter:image`,
          content: twitter?.image?.url || "",
        },
      ].concat(meta && meta.length > 0 ? meta : [])}
    >
      <link rel="canonical" href={pageUrl} />
    </Helmet>
  );
}

export default Seo;

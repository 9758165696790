import React, { useState, useEffect } from "react";
import {
  switchStyle,
  switchCeckbox,
  switchLabel,
  switchButton,
  switchButtonChecked,
  switchButtonAlwaysOn,
} from "./Switch.module.scss";

export const Switch = ({
  uid = "",
  defaultOn = false,
  alwaysOn = false,
  reset = false,
  callback = () => {},
}) => {
  const [isOn, setIsOn] = useState(defaultOn || alwaysOn);
  const backgroundColor = alwaysOn ? "#700650" : "#A572FF";
  const backgroundColorChecked = "#A572FF";

  useEffect(() => {
    if (reset === false && !alwaysOn) {
      setIsOn(defaultOn);
    }
  }, [reset, alwaysOn, defaultOn]);

  return (
    <div
      key={`Switch-${uid ? uid : Date.now()}`}
      className={switchStyle}
      style={alwaysOn ? { pointerEvents: "none" } : null}
    >
      <input
        onChange={() => {
          if (alwaysOn) return null;
          const newState = !isOn;
          setIsOn(newState);
          callback(newState);
        }}
        defaultChecked={alwaysOn ? true : isOn}
        className={switchCeckbox}
        id={`SwitchCheckbox-${uid}`}
        type="checkbox"
      />
      <label
        style={{
          background:
            isOn && !alwaysOn ? backgroundColorChecked : backgroundColor,
        }}
        className={switchLabel}
        htmlFor={`SwitchCheckbox-${uid}`}
      >
        <div>
          <span
            className={`${switchButton} ${isOn ? switchButtonChecked : null} ${
              alwaysOn ? switchButtonAlwaysOn : null
            }`}
          />
        </div>
      </label>
    </div>
  );
};

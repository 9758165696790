import * as React from "react";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import Link from "../components/Link/Link.js";
import { CustomLink } from "../utils/CustomLink";

export const ImageGallery = ({ slice }) => {
  return (
    <section className="image-gallery content-section">
      <RichText
        render={slice.primary.gallery_title.raw}
        serializeHyperlink={CustomLink}
      />
      <div className="gallery">
        {slice.items.map((galleryItem, index) => (
          <div className="gallery-item" key={`gallery-item=${index}`}>
            <img src={galleryItem.image.url} alt={galleryItem.image.alt} />
            <RichText
              render={galleryItem.image_description.raw}
              serializeHyperlink={CustomLink}
            />
            <p className="gallery-link">
              <Link to={galleryItem.link.url}>
                {RichText.asText(galleryItem.link_label.raw)}
              </Link>
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export const query = graphql`
  fragment HomepageDataBodyImageGallery on PrismicHomepageDataBodyImageGallery {
    primary {
      gallery_title {
        raw
      }
    }
    items {
      image {
        url
        alt
      }
      image_description {
        raw
      }
      link {
        url
        type
        uid
      }
      link_label {
        raw
      }
    }
  }
`;

import * as React from "react";

import { FullWidthImage } from "../slices/FullWidthImage";
import { ImageGallery } from "../slices/ImageGallery";
import { ImageHighlight } from "../slices/ImageHighlight";
import { Quote } from "../slices/Quote";
import { Text } from "../slices/Text/Text.js";
import { PlaceholderProject } from "../slices/PlaceholderProject/PlaceholderProject.js";
import { PlaceholderJury } from "../slices/PlaceholderJury/PlaceholderJury.js";
import { EmbedVideo } from "../slices/EmbedVideo/EmbedVideo.js";
import { Selectors } from "../slices/Selectors/Selectors.js";
import { CookieModal } from "../slices/CookieModal/CookieModal.js";
import { CookieBanner } from "../slices/CookieBanner/CookieBanner.js";
import { TextWithGallery } from "../slices/TextWithGallery/TextWithGallery.js";

export const SliceZone = ({ noMain, location, sliceZone }) => {
  const sliceComponents = {
    full_width_image: FullWidthImage,
    image_gallery: ImageGallery,
    image_highlight: ImageHighlight,
    quote: Quote,
    text: Text,
    text_1: Text,
    placeholder_projects: PlaceholderProject,
    placeholder_jury: PlaceholderJury,
    embed_video: EmbedVideo,
    selectors: Selectors,
    modal: CookieModal,
    banner: CookieBanner,
    text_with_gallery: TextWithGallery,
  };

  const sliceZoneContent = sliceZone.map((slice, index) => {
    const SliceComponent =
      slice.slice_type === "placeholder"
        ? sliceComponents[slice.slice_type.concat("_", slice.primary.key)]
        : sliceComponents[slice.slice_type];
    if (SliceComponent) {
      return (
        <SliceComponent
          location={location}
          slice={slice}
          key={`slice-${index}`}
        />
      );
    }
    return null;
  });

  return !noMain ? (
    <main className="container">{sliceZoneContent}</main>
  ) : (
    sliceZoneContent
  );
};

import React, { useState } from "react";
import { Header } from "./Header/Header.js";
import Footer from "./Footer/Footer.js";
import { ConsentProvider } from "./ConsentManager/ConsentContext.js";
import { ConsentManager } from "./ConsentManager/ConsentManager.js";
import { ShareOverlayWrapper } from "./ShareOverlay/ShareOverlay";
import "./../styles/reset.css";
import "./../styles/common.scss";
import "./../styles/style.css";
import * as colorPalette from "../styles/_export.module.scss";

export const ThemeContext = React.createContext();

const ThemeProvider = ({ children }) => {
  const [sortedCards, setSortedCards] = useState([]);
  const [url, setUrl] = useState(
    typeof window !== "undefined" ? window.location.href : ""
  );
  const [showModal, setShowModal] = useState(false);

  const value = {
    ...colorPalette,
    projectCards: {
      sortedCards,
      setSortedCards,
    },
    setUrl,
    url,
    showModal,
    setShowModal,
  };

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const Layout = React.forwardRef(
  ({ backLinkState, state, isHomepage, isProject, children, backTo }, ref) => {
    return (
      <ThemeProvider>
        <ConsentProvider
          hideConsent={(state && state.hideConsentManager) || false}
        >
          <ConsentManager />
          <Header
            isHomepage={isHomepage}
            isProject={isProject}
            backTo={backTo}
            ref={ref}
            backLinkState={backLinkState}
          />
          {children}
          <Footer />

          <ShareOverlayWrapper />
        </ConsentProvider>
      </ThemeProvider>
    );
  }
);

export default Layout;

import React from "react";

const Twitter = ({ fill = "#FFCEDD" }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon--twitter"
    >
      <circle cx="24" cy="24" r="19.25" stroke={fill} strokeWidth="1.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 17.5494C33.2647 17.876 32.4734 18.096 31.6435 18.1953C32.4908 17.6873 33.1414 16.8834 33.4474 15.9255C32.6554 16.3961 31.7761 16.7367 30.8422 16.9214C30.0936 16.1241 29.0263 15.6255 27.8471 15.6255C25.5812 15.6255 23.744 17.4627 23.744 19.7285C23.744 20.0499 23.7807 20.3632 23.8507 20.6638C20.4402 20.4932 17.4171 18.8593 15.3932 16.3768C15.0399 16.9827 14.8379 17.6874 14.8379 18.4393C14.8379 19.8625 15.5612 21.1185 16.6632 21.8544C15.9899 21.8331 15.3579 21.6484 14.8039 21.3411V21.3924C14.8039 23.381 16.2192 25.0389 18.0951 25.4168C17.7511 25.5102 17.3884 25.5608 17.0145 25.5608C16.7498 25.5608 16.4925 25.5348 16.2418 25.4868C16.7638 27.1167 18.2797 28.3033 20.0749 28.3366C18.671 29.4366 16.9018 30.0932 14.9793 30.0932C14.6473 30.0932 14.3206 30.0739 14 30.0352C15.8159 31.1991 17.9724 31.8791 20.2896 31.8791C27.8364 31.8791 31.9635 25.6268 31.9635 20.2052C31.9635 20.0272 31.9601 19.8499 31.9515 19.6746C32.7541 19.0946 33.45 18.372 34 17.5494Z"
        fill={fill}
      />
    </svg>
  );
};

export default Twitter;

import React from "react";

const Logo = ({ fill = "#700650", stroke = "none" }) => {
  return (
    <svg
      width="120"
      height="40"
      viewBox="0 0 120 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className="icon--logo"
    >
      <path
        d="M17.137 21.3228C17.0809 18.2967 17.0529 15.2426 17.0529 10.7875C17.0529 6.1923 17.0529 3.05412 17.1089 0.308214H12.5418C12.0094 2.24156 10.9166 6.52854 9.8519 10.5073C9.59972 11.46 9.31953 12.7489 9.09537 14.0378H8.98329C8.75914 12.7769 8.50696 11.4319 8.2828 10.4793C7.16203 6.50052 6.12531 2.24156 5.59294 0.308214H0.633488C0.661508 3.05412 0.661508 6.1923 0.661508 10.7875C0.661508 15.2426 0.661508 18.2967 0.605469 21.3228H3.96781C3.91177 17.4561 3.85573 14.7102 3.85573 10.5914C3.82771 8.4899 3.71563 6.36042 3.65959 4.09084H3.74365C4.05186 5.91211 4.44414 7.92951 4.80839 9.44256L7.83449 21.3228H9.48764L12.5137 9.61068C12.878 8.26575 13.2423 6.13626 13.5785 4.14688H13.6625C13.6065 6.30438 13.4664 8.65802 13.4664 10.6194C13.4384 14.6822 13.4104 17.4281 13.3263 21.3228H17.137Z"
        fill={fill}
      />
      <path
        d="M20.7537 16.5315C19.4087 16.5315 18.6522 17.3721 18.6522 18.9972C18.6522 20.6223 19.3807 21.4909 20.7537 21.4909C22.1546 21.4909 22.8551 20.6223 22.8551 18.9972C22.8551 17.3721 22.0986 16.5315 20.7537 16.5315Z"
        fill={fill}
      />
      <path
        d="M30.4788 2.46571C30.675 3.47442 30.9271 4.90341 31.2634 6.52854C31.6556 8.57396 32.104 11.0397 32.4962 13.3653C31.7397 13.3933 30.9832 13.3933 30.3948 13.3933C29.8344 13.3933 29.0779 13.3933 28.3213 13.3653C28.7416 11.0677 29.1899 8.63 29.5542 6.58458C29.8904 4.87539 30.1706 3.47442 30.3387 2.46571H30.4788ZM38.1281 21.3228C36.7552 16.0552 34.1214 2.94205 33.5049 0.308214H27.4527C26.8923 2.91403 24.2305 16.1392 22.9696 21.3228H26.9764C27.0885 20.3982 27.3967 18.5489 27.789 16.3354C28.7696 16.3354 29.7223 16.3354 30.3948 16.3354C31.1233 16.3354 32.0759 16.3354 33.0286 16.3354C33.4209 18.633 33.7011 20.5103 33.7851 21.3228H38.1281Z"
        fill={fill}
      />
      <path
        d="M45.0441 17.8484C44.4277 17.8484 43.9514 17.8484 43.391 17.7924C43.363 16.2513 43.363 14.1218 43.363 10.9276C43.363 8.54594 43.363 5.85607 43.419 3.83867C43.9514 3.81065 44.5118 3.78263 45.1282 3.78263C47.5939 3.78263 48.6586 4.51114 48.6586 10.7034C48.6586 17.0078 47.6499 17.8484 45.0441 17.8484ZM39.4683 0.308214C39.5523 2.85799 39.5523 6.38844 39.5523 10.8435C39.5523 15.2986 39.5523 18.4368 39.4683 21.3228H44.9881C50.3959 21.3228 52.4973 18.8571 52.4973 10.6194C52.4973 2.1575 50.1717 0.308214 45.0722 0.308214H39.4683Z"
        fill={fill}
      />
      <path
        d="M70.2402 7.70535C70.9127 7.70535 71.6132 7.73338 72.0615 7.76139C72.0895 7.28506 72.0895 6.83675 72.0895 6.41646C72.0895 2.91403 70.4924 0 65.9252 0C61.1059 0 58.9203 3.22224 58.9484 10.5633C58.9484 17.9044 61.0498 21.631 65.7851 21.631C71.361 21.631 72.3137 17.8484 71.9494 10.087C70.6325 10.1431 69.2876 10.115 68.3069 10.115C67.4943 10.115 66.4296 10.115 65.3648 10.087C65.3928 10.5353 65.3928 11.0957 65.3928 11.7682C65.3928 12.1044 65.3928 12.6928 65.3648 12.973C66.2054 12.945 67.3542 12.945 68.3349 12.917C68.4189 17.0639 67.8586 18.2967 65.9812 18.2967C63.6837 18.2967 62.8431 16.5875 62.815 10.6194C62.8431 4.65123 63.8518 3.39036 65.9252 3.39036C67.4663 3.39036 68.3349 4.23094 68.3349 6.72467C68.3349 7.03289 68.3069 7.36912 68.2788 7.76139C68.7832 7.70536 69.4837 7.70535 70.2402 7.70535Z"
        fill={fill}
      />
      <path
        d="M80.4717 2.46571C80.6678 3.47442 80.92 4.90341 81.2562 6.52854C81.6485 8.57396 82.0968 11.0397 82.4891 13.3653C81.7326 13.3933 80.976 13.3933 80.3876 13.3933C79.8272 13.3933 79.0707 13.3933 78.3142 13.3653C78.7345 11.0677 79.1828 8.63 79.5471 6.58458C79.8833 4.87539 80.1635 3.47442 80.3316 2.46571H80.4717ZM88.121 21.3228C86.7481 16.0552 84.1142 2.94205 83.4978 0.308214H77.4456C76.8852 2.91403 74.2234 16.1392 72.9625 21.3228H76.9693C77.0813 20.3982 77.3896 18.5489 77.7818 16.3354C78.7625 16.3354 79.7152 16.3354 80.3876 16.3354C81.1161 16.3354 82.0688 16.3354 83.0215 16.3354C83.4137 18.633 83.6939 20.5103 83.778 21.3228H88.121Z"
        fill={fill}
      />
      <path
        d="M106.212 21.3228C106.155 18.2967 106.127 15.2426 106.127 10.7875C106.127 6.1923 106.127 3.05412 106.184 0.308214H101.616C101.084 2.24156 99.9912 6.52854 98.9265 10.5073C98.6743 11.46 98.3941 12.7489 98.1699 14.0378H98.0579C97.8337 12.7769 97.5815 11.4319 97.3574 10.4793C96.2366 6.50052 95.1999 2.24156 94.6675 0.308214H89.7081C89.7361 3.05412 89.7361 6.1923 89.7361 10.7875C89.7361 15.2426 89.7361 18.2967 89.68 21.3228H93.0424C92.9863 17.4561 92.9303 14.7102 92.9303 10.5914C92.9023 8.4899 92.7902 6.36042 92.7342 4.09084H92.8182C93.1264 5.91211 93.5187 7.92951 93.8829 9.44256L96.9091 21.3228H98.5622L101.588 9.61068C101.953 8.26575 102.317 6.13626 102.653 4.14688H102.737C102.681 6.30438 102.541 8.65802 102.541 10.6194C102.513 14.6822 102.485 17.4281 102.401 21.3228H106.212Z"
        fill={fill}
      />
      <path
        d="M109.128 0.308214C109.212 2.77393 109.212 6.36042 109.212 10.8155C109.212 15.2986 109.212 18.633 109.128 21.3228H119.999C119.971 20.9025 119.971 20.118 119.971 19.6136C119.971 18.9972 119.971 18.1566 119.999 17.7363C117.562 17.7643 115.012 17.7643 112.994 17.7643V12.2165C115.404 12.2165 117.786 12.2445 119.803 12.2725V8.96623C117.758 9.05029 115.432 9.05029 112.994 9.05029V3.72659C115.012 3.72659 117.45 3.75461 119.999 3.78263C119.971 3.36234 119.971 2.49373 119.971 1.93334C119.971 1.40097 119.971 0.672467 119.999 0.308214H109.128Z"
        fill={fill}
      />
      <path
        d="M11.0379 30.9947C11.3654 30.9754 11.6544 30.9754 12.0011 30.9754C12.3286 30.9754 12.656 30.9754 12.9643 30.9947C12.9835 30.725 12.9835 30.5324 12.9835 30.2627C12.9835 27.0842 11.115 24.8882 6.87703 24.8882C2.56203 24.8882 0 27.5465 0 32.2661C0 37.4094 2.81245 39.8366 6.89629 39.8366C11.0379 39.8173 13.0413 37.7176 13.0413 34.2887C13.0413 34.0576 13.022 33.9034 13.022 33.7108H11.0764V34.1539C11.0764 36.6003 9.80507 38.0451 6.87703 38.0451C4.02605 38.0451 2.00339 36.2728 2.00339 32.2275C2.02266 28.2207 4.00678 26.6604 6.79998 26.6604C9.59317 26.6604 11.0379 27.8547 11.0379 30.5709V30.9947Z"
        fill={fill}
      />
      <path
        d="M26.6568 25.1386C26.7338 26.6604 26.7531 29.7618 26.7531 31.4763C25.5202 31.4955 23.9792 31.4955 22.5537 31.4955C21.1667 31.4955 19.6256 31.4955 18.3735 31.4763C18.3735 29.7618 18.3928 26.6604 18.4506 25.1386H16.3509C16.4472 27.0264 16.4086 29.2995 16.4086 32.3624C16.4086 35.4253 16.4279 37.5057 16.3509 39.5861H18.4506C18.3928 37.9102 18.3735 34.9437 18.3735 33.3063C19.6256 33.287 21.1667 33.287 22.5537 33.287C23.9792 33.287 25.5202 33.287 26.7531 33.3063C26.7531 34.9437 26.7338 37.9102 26.6568 39.5861H28.795C28.7372 37.5057 28.7179 35.4253 28.7179 32.3624C28.7179 29.2995 28.718 27.0264 28.795 25.1386H26.6568Z"
        fill={fill}
      />
      <path
        d="M38.0729 26.2174C38.3233 26.9301 38.6893 27.9125 39.3057 29.4151C39.8451 30.8406 40.5386 32.5935 41.1743 34.2695C40.1148 34.2887 38.9205 34.2887 38.0536 34.2887C37.2446 34.2887 36.0502 34.2887 35.01 34.2695C35.6457 32.6321 36.3007 30.9369 36.84 29.5307C37.4565 27.9125 37.8225 26.9108 38.0729 26.2174ZM45.4893 39.5861C43.9097 36.1187 40.4038 27.2768 39.5369 25.1386H36.6281C35.8961 27.1805 32.2361 36.4462 30.8106 39.5861H33.0066C33.2378 38.8927 33.7771 37.4672 34.4128 35.7913C35.6842 35.7913 37.1097 35.772 38.0536 35.772C39.1131 35.772 40.4615 35.7913 41.7715 35.8105C42.4071 37.5057 42.9465 38.9505 43.1199 39.5861H45.4893Z"
        fill={fill}
      />
      <path
        d="M57.8281 25.1386C57.9051 26.5834 57.9437 30.5131 57.9629 33.942C57.9629 34.9437 58.0015 36.0224 58.0978 37.1204L58.04 37.1397C57.6162 36.2536 57.0383 35.2519 56.5374 34.5006C54.2066 31.091 50.7584 26.3715 50.0457 25.1386H47.6185C47.657 26.853 47.657 29.2995 47.657 32.3624C47.657 35.4445 47.6378 37.7176 47.6185 39.5861H49.5063C49.4485 38.334 49.4485 35.1748 49.4485 30.5516C49.4293 29.4921 49.3907 28.3171 49.3137 27.142L49.3522 27.1227C49.7953 28.0281 50.3924 29.0105 51.0089 29.9545C53.5516 33.5182 56.7879 38.1029 57.6355 39.5861H59.6966C59.6774 37.6791 59.6774 35.5216 59.6774 32.3431C59.6774 29.2802 59.6966 26.8145 59.7159 25.1386H57.8281Z"
        fill={fill}
      />
      <path
        d="M75.6621 31.9771C74.41 31.9964 72.9267 31.9964 71.9828 31.9964C71.1544 31.9964 70.0372 31.9964 68.9199 31.9578C68.9392 32.2083 68.9391 32.478 68.9391 32.844C68.9391 32.9788 68.9392 33.2678 68.9199 33.4026C70.095 33.3833 72.4258 33.3641 73.7743 33.3641C73.9476 36.9085 72.8111 38.1607 69.729 38.1607C66.7239 38.1607 64.7975 36.2728 64.7975 32.2661C64.7975 28.1822 66.7624 26.5834 69.6904 26.5834C72.4643 26.5834 73.755 27.7584 73.755 29.8389C73.755 29.993 73.755 30.2241 73.7357 30.4168C73.9862 30.3975 74.3329 30.3975 74.7374 30.3975C75.1034 30.3975 75.4502 30.3975 75.6813 30.4168C75.7006 30.1278 75.7006 29.9545 75.7006 29.6655C75.7006 27.0457 73.9669 24.8882 69.729 24.8882C65.3947 24.8882 62.7941 27.3732 62.7941 32.2275C62.8134 37.0819 65.414 39.8366 69.6712 39.8366C74.7952 39.8366 76.0281 37.2168 75.6621 31.9771Z"
        fill={fill}
      />
      <path
        d="M89.9513 25.1386H79.1445C79.2023 26.776 79.2023 29.2995 79.2023 32.3624C79.2023 35.4445 79.2023 37.7947 79.1445 39.5861H89.9513V38.7386C89.9513 38.4303 89.932 38.0451 89.9513 37.8139C86.9269 37.8524 83.5944 37.8717 81.1287 37.8717C81.1287 36.6774 81.1287 34.9244 81.1287 33.0944C84.076 33.0944 87.3122 33.1137 89.7587 33.1522C89.7587 32.7476 89.7587 31.8808 89.7779 31.4955C87.2737 31.5341 84.1145 31.5341 81.1287 31.5341L81.1479 26.8145C83.5751 26.8145 86.8499 26.8338 89.9513 26.853C89.932 26.6604 89.9513 26.2366 89.9513 25.9477V25.1386Z"
        fill={fill}
      />
      <path
        d="M95.522 32.5743C95.522 30.7443 95.522 28.7794 95.5413 26.8145C96.62 26.7567 98.7197 26.7375 99.5095 26.7375C102.129 26.7375 103.17 27.5465 103.17 29.704C103.17 31.4185 102.052 32.6128 99.8563 32.6128C97.9685 32.6128 96.1962 32.5935 95.522 32.5743ZM101.571 33.4797C103.959 33.0751 105.077 31.7074 105.077 29.4536C105.077 26.4678 103.227 25.1386 99.9333 25.1386H93.5572C93.5957 27.2191 93.6342 29.2995 93.6342 32.3624C93.6342 35.4445 93.5957 37.4864 93.5572 39.5861H95.6761C95.5798 38.2955 95.5413 36.3692 95.522 34.1346H99.837C102.052 34.1346 102.38 34.9822 103.478 38.5652C103.709 39.4128 103.786 39.5861 104.229 39.5861H106.059C105.77 39.0275 105.443 37.9488 104.961 36.5425C104.191 34.3658 103.555 33.8071 101.571 33.5567V33.4797Z"
        fill={fill}
      />
      <path
        d="M114.17 38.2184C111.28 38.2184 109.97 37.2168 109.97 35.2134C109.97 35.0593 109.99 34.8281 110.009 34.6162C109.739 34.6355 109.392 34.6547 108.988 34.6547C108.641 34.6547 108.314 34.6355 108.063 34.5969C108.044 34.9437 108.025 35.1941 108.025 35.5408C108.025 38.3148 109.97 39.8366 114.189 39.8366C118.003 39.8366 119.949 38.3533 119.949 35.6949C119.949 33.2485 118.504 32.0156 114.459 31.4377C110.799 30.9562 110.221 29.993 110.221 28.7601C110.221 27.2576 111.396 26.5063 113.804 26.5063C116.635 26.5063 117.772 27.5273 117.772 29.2032C117.772 29.4151 117.772 29.7426 117.753 30.0315C117.965 30.0315 118.331 30.0122 118.677 30.0122C119.024 30.0122 119.352 30.0315 119.583 30.0315C119.602 29.6462 119.602 29.3958 119.602 29.0105C119.602 26.41 117.888 24.8882 113.842 24.8882C110.201 24.8882 108.275 26.3137 108.275 28.7794C108.275 31.1295 109.624 32.4587 113.611 32.9788C117.156 33.4411 118.08 34.2695 118.08 35.7527C118.08 37.3516 116.944 38.2184 114.17 38.2184Z"
        fill={fill}
      />
    </svg>
  );
};

export default Logo;

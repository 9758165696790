import React, { useRef } from "react";
import { graphql } from "gatsby";
import { embedVideo } from "./EmbedVideo.module.scss";
import IsInView from "../../utils/IsInView.js";

export const EmbedVideo = ({ slice, noSection }) => {
  const video = useRef(null);
  const elem = (
    <IsInView
      callback={(inView) => {
        if (video.current) {
          if (!inView) {
            video.current.src = "undefined";
          }
          if (video.current.src.includes("undefined")) {
            video.current.src = video.current.getAttribute("altsrc");
          }
        }
      }}
    >
      <div className={embedVideo}>
        {slice.primary.embed_url.url ? (
          <iframe
            ref={video}
            src={slice.primary.embed_url.url}
            altSrc={slice.primary.embed_url.url}
            title={slice.primary.embed_url.url}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        ) : null}
      </div>
    </IsInView>
  );
  return !noSection ? <section>{elem}</section> : elem;
};

export const query = graphql`
  fragment HomepageDataBodyEmbedVideo on PrismicHomepageDataBodyEmbedVideo {
    primary {
      video_title
      embed_url {
        url
      }
    }
  }
`;

// extracted by mini-css-extract-plugin
export var colorPrimary = "#ff5ccd";
export var colorP1 = "#ad007a";
export var colorP2 = "#f433af";
export var colorP3 = "#ff63be";
export var colorP4 = "#ff81f8";
export var colorP5 = "#d30760";
export var colorP6 = "#ff6b89";
export var colorP7 = "#ff99a0";
export var colorP8 = "#fb73a3";
export var colorP9 = "#f60072";
export var colorP10 = "#ff4684";
export var colorP92 = "#ff009b";
export var colorP102 = "#ff5ea7";
export var colorP11 = "#ec009f";
export var colorP12 = "#ffa9e7";
export var colorP13 = "#ffcedd";
export var colorP14 = "#ffb9ff";
export var colorS1 = "#ff9812";
export var colorS2 = "#ffcf00";
export var colorS3 = "#ffff3e";
export var colorS4 = "#006b57";
export var colorS5 = "#7dd782";
export var colorS6 = "#00d357";
export var colorS7 = "#82cec2";
export var colorS8 = "#3391f4";
export var colorS9 = "#a7d0ff";
export var colorS10 = "#8b00ba";
export var colorS11 = "#a572ff";
export var colorS12 = "#d8b8ff";
export var colorWhite = "#ffffff";
export var colorBlack = "#000000";
export var colorFont = "#700650";
export var colorNav = "#831d66";
import React, { useState, useEffect, useRef, useContext } from "react";
import { ThemeContext } from "../Layout";
import Link from "../Link/Link.js";
import { RichText } from "prismic-reactjs";
import { CustomLink } from "../../utils/CustomLink";
import { Share, Like } from "../Icons/MadLogos.js";

import "./card.scss";

export const Card = ({
  delay = 0,
  isProjectPage = false,
  cardType = "project",
  ...props
}) => {
  const cardRef = useRef();

  const returnObj = () => {
    switch (cardType) {
      case "Project":
        return <CardProject {...props} />;
        break;
      case "Joker":
        return <CardJoker {...props} />;
        break;
      case "Jury":
        return <CardJury {...props} />;
        break;
      default:
        return <CardProject {...props} />;
        break;
    }
  };
  return (
    <div
      data-aos={
        cardType === "Joker" || isProjectPage ? "slide-right" : "fade-up"
      }
      data-aos-easing="ease-out-quad"
      data-aos-delay={delay}
      data-aos-duration={cardType === "Joker" || isProjectPage ? 600 : 500}
      data-aos-once={true}
    >
      <div
        className="component-card"
        ref={cardRef}
        onClick={(e) => {
          if (cardType !== "Jury") {
            const touch = matchMedia("(hover: none)").matches;
            if (cardRef.current) {
              const hasClass =
                cardRef.current.classList.contains("active-card");

              if (touch && !hasClass) {
                cardRef.current.classList.add("active-card");
              } else if (hasClass) {
                cardRef.current.classList.remove("active-card");
              }
            }
          }
        }}
      >
        {returnObj()}
      </div>
    </div>
  );
};

const CardProject = ({
  title,
  client,
  category,
  categories,
  year,
  image,
  text,
  linkUrl,
  stats = { likes: false, shares: false },
  callback = () => {},
  allowEdit,
  idx,
  sortedArray,
}) => {
  const [originalStats, setOriginalStats] = useState(false);
  const [likes, setLikes] = useState(false);
  const [shares, setShares] = useState(false);
  const { setUrl, setShowModal } = useContext(ThemeContext);

  // const windowGlobal = typeof window !== "undefined" && window;

  useEffect(() => {
    if (
      stats &&
      typeof stats.likes === "number" &&
      typeof stats.shares === "number"
    ) {
      setOriginalStats(stats);
      setLikes(stats.likes);
      setShares(stats.shares);
    } else if (
      stats &&
      (typeof stats.likes !== "number" || typeof stats.shares !== "number")
    ) {
      setLikes(
        stats.likes === true
          ? (originalStats.likes || 0) + 1
          : originalStats.likes || 0
      );
      setShares(
        stats.shares === true
          ? (originalStats.shares || 0) + 1
          : originalStats.shares || 0
      );
    }
  }, [stats, originalStats, setOriginalStats]);

  return (
    <div className="component-card-project">
      <span className="component-card-project__title">
        {title?.text || "Title"}
      </span>
      <div className="component-card-project__tag-row">
        <div className="component-card-project__tag-row--item">
          {client || "Client"}
        </div>
        <div className="component-card-project__tag-row--item">
          {category || "Category"}
        </div>
        <div className="component-card-project__tag-row--item">
          {year || "Year"}
        </div>
      </div>
      <div
        className="component-card-project__image"
        style={{
          backgroundImage: `url(${
            image?.url ||
            "https://via.placeholder.com/264x176/0f0f0f/fff/?text=264x176"
          })`,
        }}
      ></div>
      <p className="component-card-project__text">
        {text ||
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut sollicitudin nisi, ut pretium velit. Nulla tempus felis eu enim. scelerisque tristique."}
      </p>
      <ul className="component-card-project__categories">
        {categories &&
          categories.map((cat, idx) => {
            if (cat.title.toLowerCase().includes("year")) return null;
            if (cat.title.toLowerCase().includes("likes")) return null;
            if (cat.title.toLowerCase().includes("shares")) return null;

            return (
              <li
                className="component-card-project__categories--item"
                key={`component-card-${idx}`}
              >
                <span>{cat.title}</span>
                {/* <span>{zeroPad(cat.rating, 2)}</span> */}
                <span>{cat.rating || 0}</span>
              </li>
            );
          })}
      </ul>
      <div className="component-card-project__hover">
        <div className="component-card-project__hover--inner">
          <div className="component-card-project__hover--icons">
            <div
              className={`icon`}
              onClick={(e) => {
                e.stopPropagation();
                const tmpLikes = stats.likes ? stats.likes : false;
                const tmpShares = true;

                const shareUrl =
                  typeof window !== "undefined"
                    ? window.location.origin.concat(linkUrl)
                    : "";

                setUrl(shareUrl);
                setShowModal(true);

                callback({
                  likes: tmpLikes,
                  shares: tmpShares,
                });
              }}
            >
              <span>
                <Share />
              </span>
              <span>{shares !== true ? shares || 0 : 0}</span>
            </div>
            <div
              className={`icon ${
                stats.likes === true || !allowEdit ? "active" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                let tmpLikes = false;
                if (allowEdit) {
                  tmpLikes = stats.likes === true ? !stats.likes : true;
                }

                const tmpShares = stats.shares ? stats.shares : false;

                callback({
                  likes: tmpLikes,
                  shares: tmpShares,
                });
              }}
            >
              <span>
                <Like />
              </span>
              <span>{likes !== true ? likes || 0 : 0}</span>
            </div>
          </div>
          <Link
            to={linkUrl || "/"}
            state={{ idx: idx, sortedArray: sortedArray }}
            className="component-card-project__hover--button"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            View card
          </Link>
        </div>
      </div>
    </div>
  );
};

const CardJoker = ({
  description,
  category,
  title,
  year_of_creation,
  client,
  card_image,
  url,
  idx,
  sortedArray,
  allowEdit,
  stats,
  callback,
}) => {
  const [originalStats, setOriginalStats] = useState(false);
  const [likes, setLikes] = useState(false);
  const [shares, setShares] = useState(false);
  const { setUrl, setShowModal } = useContext(ThemeContext);

  const truncateJSONText = (t) => {
    var text = RichText.asText(t);
    if(text){
      if(text.length > 150) return text.substring(0, 150) + "...";
      else return text;
    }
  }
  
  useEffect(() => {
    if (
      stats &&
      typeof stats.likes === "number" &&
      typeof stats.shares === "number"
    ) {
      setOriginalStats(stats);
      setLikes(stats.likes);
      setShares(stats.shares);
    } else if (
      stats &&
      (typeof stats.likes !== "number" || typeof stats.shares !== "number")
    ) {
      setLikes(
        stats.likes === true
          ? (originalStats.likes || 0) + 1
          : originalStats.likes || 0
      );
      setShares(
        stats.shares === true
          ? (originalStats.shares || 0) + 1
          : originalStats.shares || 0
      );
    }
  }, [stats, originalStats, setOriginalStats]);
  return (
    <div className="component-card-joker">
      {/* <Link
        to={url || "/"}
        state={{ isJoker: true, idx: idx, sortedArray: sortedArray }}
      > */}
      <div className="component-card-joker__inner">
        <span className="component-card-joker__title">
          {title?.text || "Title"}
        </span>
        <div className="component-card-joker__tag-row">
          <div className="component-card-joker__tag-row--item">
            {client || "Client"}
          </div>
          <div className="component-card-joker__tag-row--item">
            {category || "Category"}
          </div>
          <div className="component-card-joker__tag-row--item">
            {year_of_creation || "Year"}
          </div>
        </div>
        <div
          className="component-card-joker__image"
          style={{
            backgroundImage: `url(${
              card_image?.url ||
              "https://via.placeholder.com/216x223/0f0f0f/fff/?text=216x223"
            })`,
          }}
        ></div>
        <p className="component-card-joker__text">
          {truncateJSONText(description.raw) ||
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut sollicitudin nisi, ut pretium velit. Nulla tempus felis eu enim. scelerisque tristique."}
        </p>
        <span className="component-card-joker__button">
          This is M.AD, you win this round
        </span>
      </div>
      <div className="component-card-joker__hover">
        <div className="component-card-joker__hover--inner">
          <div className="component-card-joker__hover--icons">
            <div
              className={`icon`}
              onClick={(e) => {
                e.stopPropagation();
                const tmpLikes = stats.likes ? stats.likes : false;
                const tmpShares = true;

                const shareUrl =
                  typeof window !== "undefined"
                    ? window.location.origin.concat(url)
                    : "";

                setUrl(shareUrl);
                setShowModal(true);

                callback({
                  likes: tmpLikes,
                  shares: tmpShares,
                });
              }}
            >
              <span>
                <Share />
              </span>
              <span>{shares !== true ? shares || 0 : 0}</span>
            </div>
            <div
              className={`icon ${
                stats.likes === true || !allowEdit ? "active" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                let tmpLikes = false;
                if (allowEdit) {
                  tmpLikes = stats.likes === true ? !stats.likes : true;
                }

                const tmpShares = stats.shares ? stats.shares : false;

                callback({
                  likes: tmpLikes,
                  shares: tmpShares,
                });
              }}
            >
              <span>
                <Like />
              </span>
              <span>{likes !== true ? likes || 0 : 0}</span>
            </div>
          </div>
          <Link
            to={url || "/"}
            state={{ isJoker: true, idx: idx, sortedArray: sortedArray }}
            className="component-card-joker__hover--button"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            View card
          </Link>
        </div>
      </div>
      {/* </Link> */}
    </div>
  );
};

const CardJury = ({
  image,
  name,
  job_position,
  country_of_origin,
  enroled_in,
  button_link_external,
  card_color = "FONT",
}) => {
  const { colorFont, colorP7, colorP10, colorP13, colorP12 } =
    useContext(ThemeContext);

  let bgColor = null;
  let titleColor = null;
  let fontColor = null;

  switch (card_color) {
    case "FONT":
      bgColor = colorFont;
      titleColor = colorP13;
      fontColor = colorP12;
      break;
    case "P7":
      bgColor = colorP7;
      titleColor = colorFont;
      fontColor = colorP13;
      break;
    case "P10":
      bgColor = colorP10;
      titleColor = colorP12;
      fontColor = colorFont;
      break;
    default:
      bgColor = colorFont;
      titleColor = colorP13;
      fontColor = colorP12;
      break;
  }

  return (
    <div className="component-card-jury" style={{ backgroundColor: bgColor }}>
      <div
        className="component-card-jury__image"
        style={{
          backgroundImage: `url(${
            image.url ||
            "https://via.placeholder.com/264x216/0f0f0f/fff/?text=264x216"
          })`,
        }}
      ></div>
      <div className="component-card-jury__inner">
        <span
          className="component-card-jury__inner--subtitle"
          style={{ color: fontColor }}
        >
          STUDENT JUROR
        </span>
        <span
          className="component-card-jury__inner--title
        "
          style={{ color: titleColor }}
        >
          {name || "Name"}
        </span>
        <span
          className="component-card-jury__inner--job
        "
          style={{ color: titleColor }}
        >
          {job_position}
        </span>
        <div className="component-card-jury__inner--bottom">
          <span
            className="component-card-jury__inner--from-title
        "
            style={{ color: fontColor }}
          >
            Originally from
          </span>
          <span
            className="component-card-jury__inner--from
        "
            style={{ color: fontColor }}
          >
            {country_of_origin || "Location"}
          </span>
          <span
            className="component-card-jury__inner--enroled-title
        "
            style={{ color: fontColor }}
          >
            Enroled at
          </span>
          <span
            className="component-card-jury__inner--enroled
        "
            style={{ color: fontColor }}
          >
            <RichText
              render={enroled_in.raw || ["Test"]}
              serializeHyperlink={CustomLink}
            />
          </span>
          {button_link_external?.url ? (
            <a
              className="component-card-jury__inner--button"
              href={button_link_external.url || "/"}
              target={button_link_external.target || "_self"}
              style={{ borderColor: titleColor, color: titleColor }}
            >
              Portfolio link
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
};

import React from "react";

const Arrow = ({ fill = "#8B00BA", next = false }) => {
  return (
    <svg
      width="38"
      height="60"
      viewBox="0 0 38 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={next ? { transform: "rotateZ(180deg)" } : null}
      className="icon--arrow"
    >
      <path
        d="M29.769 0L0 30L29.769 60L37.931 51.7719L16.3278 30.0005L37.931 8.2291L29.769 0Z"
        fill={fill}
      />
    </svg>
  );
};

const ArrowNext = (props) => {
  return <Arrow next={true} {...props} />;
};

const ArrowBack = (props) => {
  return <Arrow {...props} />;
};

const ArrowDown = ({ fill = "white" }) => {
  return (
    <svg
      width="15"
      height="8"
      viewBox="0 0 15 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon--arrow-down"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.82497 7.74406L14.1414 1.51462C14.4862 1.15221 14.4862 0.597188 14.1414 0.255938C13.7966 -0.0853126 13.2144 -0.0853126 12.8696 0.255938L7.2 5.86687L1.53045 0.255938C1.16425 -0.0853126 0.603428 -0.0853126 0.258612 0.255938C-0.0862039 0.597188 -0.0862039 1.15222 0.258612 1.51462L6.55314 7.74406C6.91934 8.08531 7.48016 8.08531 7.82497 7.74406Z"
        fill={fill}
      />
    </svg>
  );
};

export { ArrowBack, ArrowNext, ArrowDown };

import React, { useContext } from "react";
import { graphql } from "gatsby";
import { ConsentContext } from "../../components/ConsentManager/ConsentContext.js";
import { RichText } from "prismic-reactjs";
import { CustomLink } from "../../utils/CustomLink";
import "./cookieBanner.scss";

export const CookieBanner = ({ slice }) => {
  const { showBanner, cookieValues, setConsentCookie, revertSettingsView } =
    useContext(ConsentContext);

  slice = slice.primary;

  const setAllToTrue = () => {
    Object.keys(cookieValues).forEach((key) => {
      cookieValues[key] = true;
    });
    setConsentCookie(cookieValues);
  };

  return (
    <div
      className="cookie-banner"
      style={showBanner ? { display: "flex" } : null}
    >
      <div className="cookie-banner__wrapper">
        <div className="cookie-banner__content">
          <p className="cookie-banner__content--title">{slice.title}</p>
          <div className="cookie-banner__content--text">
            <RichText render={slice.text.raw} serializeHyperlink={CustomLink} />
          </div>
          <div className="cookie-banner__content--link-wrapper">
            <button className="first" onClick={setAllToTrue}>
              {slice.button_label}
            </button>
            <a onClick={revertSettingsView}>{slice.consent_change_label}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  fragment ConsentManagerDataBodyBanner on PrismicConsentmanagerDataBodyBanner {
    primary {
      button_label
      consent_change_label
      consent_change_link {
        url
        target
      }
      text {
        raw
      }
      title
    }
  }
`;

import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { RichText } from "prismic-reactjs";
import "./selectors.scss";
import Link from "../../components/Link/Link.js";
import { shuffle } from "../../utils/shuffleArray.js";

export const Selectors = ({ slice }) => {
  const { allPrismicSelectors } = useStaticQuery(
    graphql`
      query {
        allPrismicSelectors {
          nodes {
            uid
          }
        }
      }
    `
  );

  const randomSelectorUrl = "/selectors/".concat(
    shuffle(allPrismicSelectors.nodes)[
      Math.floor(Math.random() * (allPrismicSelectors.nodes.length - 1))
    ].uid
  );

  return (
    <section className="typo__section fullwidth slice-selector" id="selectors">
      <div className="typo__wrapper">
        <h2 className="typo__title">
          {RichText.asText(slice.primary.title.raw)}
        </h2>
        <p className="typo__text">{RichText.asText(slice.primary.text.raw)}</p>
        <Link to={randomSelectorUrl} className="slice-selector__button">
          {slice.primary.button_label}
        </Link>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment HomepageDataBodySelectors on PrismicHomepageDataBodySelectors {
    primary {
      button_label
      button_target {
        url
        target
      }
      text {
        raw
      }
      title {
        raw
      }
    }
  }
`;

import React, { useEffect, useState, useRef, useContext } from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import {
  FacebookShare,
  TwitterShare,
  LinkedInShare,
  Close,
} from "../Icons/MadLogos";
import { ThemeContext } from "../Layout";
import OutsideAlerter from "../../utils/DetectOutsideClick.js";
import "./shareOverlay.scss";

const BUTTON_VALUES = {
  true: "COPIED SUCCESSFULLY",
  false: "COPY TO CLIPBOARD",
};

export const ShareOverlay = ({ closeFnc = () => {} }) => {
  const { url, setUrl } = useContext(ThemeContext);
  const [copySuccess, setCopySuccess] = useState(false);
  const inputRef = useRef(null);

  const copyToClipboard = (e) => {
    inputRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setCopySuccess(true);
  };

  useEffect(() => {
    return () => {
      setCopySuccess(false);
      setUrl(typeof window !== "undefined" ? window.location.href : "");
    };
  }, [setUrl]);

  return (
    <div className="component-share-overlay">
      <OutsideAlerter
        callback={() => {
          closeFnc();
        }}
      >
        <div className="component-share-overlay--wrapper">
          <div className="component-share-overlay--wrapper__inner">
            <Close
              className="component-share-overlay--close"
              onClick={closeFnc}
            />
            <div className="component-share-overlay--share-buttons">
              <ul>
                <li>
                  <FacebookShareButton url={url}>
                    <FacebookShare />
                  </FacebookShareButton>
                </li>
                <li>
                  <LinkedinShareButton url={url}>
                    <LinkedInShare />
                  </LinkedinShareButton>
                </li>
                <li>
                  <TwitterShareButton url={url}>
                    <TwitterShare />
                  </TwitterShareButton>
                </li>
              </ul>
            </div>
            <div className="component-share-overlay--bottom">
              <input
                onClick={copyToClipboard}
                ref={inputRef}
                type="text"
                value={url}
                readOnly
              />
              <button
                className={copySuccess ? "active" : ""}
                onClick={copyToClipboard}
              >
                {BUTTON_VALUES[copySuccess]}
              </button>
            </div>
          </div>
        </div>
      </OutsideAlerter>
    </div>
  );
};

export const ShareOverlayWrapper = () => {
  const { showModal, setShowModal } = useContext(ThemeContext);

  const closeShareOverlay = () => {
    setShowModal(false);
  };

  return showModal ? <ShareOverlay closeFnc={closeShareOverlay} /> : null;
};

import React, { useContext } from "react";
import { RichText } from "prismic-reactjs";
import { graphql, useStaticQuery } from "gatsby";
import "./footer.scss";
import Link from "../Link/Link.js";
import { ConsentContext } from "../ConsentManager/ConsentContext.js";
import LogoFooter from "../../images/LogoFooter.png";
// import {
//   FacebookShareButton,
//   TwitterShareButton,
//   LinkedinShareButton,
// } from "react-share";
import { Twitter, Facebook, Instagram } from "../Icons/MadLogos.js";

const Footer = () => {
  const queryData = useStaticQuery(graphql`
    query FooterQuery {
      prismicFooter {
        data {
          title_about
          title_legal
          copyright_text
          internal_links {
            link_title
            link_target {
              url
              target
            }
          }
          text_about {
            raw
          }
        }
      }
    }
  `);

  const footerData = queryData.prismicFooter.data;
  const { showChangeSettings } = useContext(ConsentContext);
  // const url = typeof window !== "undefined" ? window.location.href : "";

  return (
    <footer>
      <div className="footer-inner">
        <div className="footer-inner__column">
          <a href="https://miamiadschool.com/" target="_blank" rel="noreferrer">
            <img src={LogoFooter} alt=""></img>
          </a>
          <ul className="footer-inner__share-wrapper">
            <li className="footer-inner__share-wrapper--icon">
              <a
                href="https://www.facebook.com/MiamiAdSchool/"
                target="_blank"
                rel="noreferrer"
              >
                <Facebook />
              </a>
            </li>
            <li className="footer-inner__share-wrapper--icon">
              <a
                href="https://twitter.com/miamiadschool"
                target="_blank"
                rel="noreferrer"
              >
                <Twitter />
              </a>
            </li>
            <li className="footer-inner__share-wrapper--icon">
              <a
                href="https://www.instagram.com/miamiadschool/"
                target="_blank"
                rel="noreferrer"
              >
                <Instagram />
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-inner__column">
          <h6 className="footer-inner__title">{footerData.title_about}</h6>
          <p className="footer-inner__text">
            {RichText.asText(footerData.text_about.raw)}
          </p>
        </div>
        <div className="footer-inner__column">
          <h6 className="footer-inner__title">{footerData.title_legal}</h6>
          <ul>
            {footerData.internal_links.map((link) => (
              <li className="footer-inner__text" key={link.link_title}>
                <Link to={link.link_target.url}>{link.link_title}</Link>
              </li>
            ))}
            <li className="footer-inner__text">
              <a onClick={showChangeSettings}>Cookie Settings</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        {footerData.copyright_text}
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";

const Like = ({ fill = "none", stroke = "#ffcedd" }) => {
  return (
    <svg
      className="icon--like"
      width="40"
      height="37"
      viewBox="0 0 40 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.0147 3.14576L34.0159 3.14651C36.2091 4.60111 37.7823 6.89564 38.2987 9.45201C38.79 11.8963 38.3771 14.4704 37.1144 16.602L37.1138 16.6031C36.9573 16.8678 36.4947 17.4604 35.6044 18.4549C34.7453 19.4144 33.5641 20.6655 32.0546 22.2131C29.189 25.1508 25.1722 29.1239 20 34.1365C14.8274 29.1242 10.8107 25.1512 7.94515 22.2135C6.43568 20.666 5.25455 19.4148 4.39555 18.4552C3.50533 17.4607 3.04277 16.868 2.88635 16.6033L2.88553 16.6019C1.6228 14.4705 1.20989 11.8962 1.70141 9.45136C2.21798 6.89497 3.79111 4.6007 5.98405 3.14658L5.98529 3.14575C8.34545 1.57735 11.3881 1.10389 14.144 1.83763L14.1473 1.8385C15.5456 2.20738 16.8475 2.89417 17.9797 3.82793C18.2987 4.09292 18.6092 4.39029 18.9416 4.72134L19.993 5.76871L21.0515 4.72845C21.4901 4.29748 21.8073 4.00479 22.0199 3.82847C23.1522 2.89441 24.4543 2.20746 25.853 1.83851L25.8563 1.83762C28.6119 1.1039 31.6545 1.57734 34.0147 3.14576Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="3"
      />
    </svg>
  );
};

export default Like;

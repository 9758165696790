// CustomLink.js file

import React from "react";
import { linkResolver } from "./LinkResolver";
import Link from "../components/Link/Link.js";

export const CustomLink = (type, element, content, children, index) => {
  if (element && element.data.link_type === "Document") {
    return (
      <Link
        to={linkResolver(element.data)}
        key={element.data.id}
        state={{ hideConsentManager: element.data.slug.includes("privacy") }}
      >
        {content}
      </Link>
    );
  }

  if (element && element.data.link_type === "Web") {
    return (
      <Link id={element.data.id} href={element.data.url}>
        {content}
      </Link>
    );
  }

  return <Link id="element"></Link>;
};

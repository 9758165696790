import React, { useContext, useState } from "react";
import { RichText } from "prismic-reactjs";
import { graphql } from "gatsby";
import { CustomLink } from "../../utils/CustomLink";
import { Switch } from "../../components/Switch/Switch.js";
import { ConsentContext } from "../../components/ConsentManager/ConsentContext.js";

import "./cookieModal.scss";

export const CookieModal = ({ slice }) => {
  const {
    showModal,
    cookieValues,
    setShowModal,
    revertSettingsView,
    setConsentCookie,
  } = useContext(ConsentContext);

  const [originalCookieValues] = useState({ ...cookieValues });

  return (
    <div
      className="cookie-modal"
      style={showModal || showModal === "static" ? { display: "flex" } : null}
    >
      <div className="cookie-modal__wrapper">
        <div className="cookie-modal__content">
          <div className="cookie-modal__content--wrapper">
            <p className="cookie-modal__content--title">
              {slice?.primary?.title || "Title"}
            </p>
            <div className="cookie-modal__content--text">
              <RichText
                render={slice?.primary?.text?.raw || ""}
                serializeHyperlink={CustomLink}
              />
            </div>
          </div>
          <div>
            {slice?.items?.map((item, idx) => (
              <div
                key={`ConsentWrapper_${item?.identifier || idx}`}
                className="cookie-modal__content--wrapper"
              >
                <div>
                  <span className="cookie-modal__content--title">
                    {item.title}
                  </span>
                  <div className="cookie-modal__content--text">
                    <RichText
                      render={item.text.raw || ""}
                      serializeHyperlink={CustomLink}
                    />
                  </div>
                </div>
                <Switch
                  uid={item?.identifier || idx}
                  alwaysOn={item?.always_on || false}
                  defaultOn={cookieValues[item?.identifier]}
                  callback={(isOn) => {
                    originalCookieValues[item.identifier] = isOn;
                  }}
                  reset={showModal}
                />
              </div>
            )) || ""}
          </div>
          <div className="cookie-modal__content--link-wrapper">
            <button
              className="cookie-modal__content--button first"
              onClick={() => {
                setConsentCookie({ ...originalCookieValues });
              }}
            >
              {slice?.primary?.button_label || "BUTTON"}
            </button>
            <button
              className="cookie-modal__content--button"
              onClick={() => {
                if (showModal === "static") {
                  setShowModal(false);
                } else {
                  revertSettingsView();
                }
              }}
            >
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  fragment ConsentManagerDataBodyModal on PrismicConsentmanagerDataBodyModal {
    items {
      identifier
      always_on
      text {
        raw
      }
      title
    }
    primary {
      button_label
      text {
        raw
      }
      title
    }
  }

  fragment ConsentManagerCategories on PrismicConsentmanagerDataBodyModal {
    items {
      identifier
      always_on
    }
  }
`;

import * as React from "react";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import { CustomLink } from "../../utils/CustomLink.js";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import "./textWithGallery.scss";

SwiperCore.use([Autoplay, Pagination]);

export const TextWithGallery = ({ slice }) => {
  if (!slice.hasOwnProperty("primary")) return null;
  if (!slice.hasOwnProperty("items")) return null;

  const items = slice.items;
  const primary = slice.primary;

  return (
    <section className="slice-text-with-gallery fullwidth">
      <div
        className={`slice-text-with-gallery__wrapper ${
          primary.gallery_position ? "right" : "left"
        }`}
      >
        <div className="slice-text-with-gallery__column">
          <div className="slice-text-with-gallery__column--title">
            {primary.text_title || ""}
          </div>
          <div className="slice-text-with-gallery__column--text">
            <RichText
              render={primary.text.raw}
              serializeHyperlink={CustomLink}
            />
          </div>
        </div>
        <div className="slice-text-with-gallery__column">
          <div className="slice-text-with-gallery__swiper">
            <Swiper
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              pagination={{
                el: ".slice-text-with-gallery__swiper--pagination",
                clickable: true,
              }}
              spaceBetween={6}
              slidesPerView={1}
              autoHeight={true}
            >
              {items.map((item, idx) => {
                item.gallery_image["src"] = item.gallery_image["url"];
                return (
                  <SwiperSlide key={`slice-text-with-gallery--slide-${idx}`}>
                    <img
                      className="slice-text-with-gallery__swiper--image"
                      {...item.gallery_image}
                      alt=""
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="slice-text-with-gallery__swiper--pagination"></div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment HomepageDataBodyTextWithGallery on PrismicHomepageDataBodyTextWithGallery {
    primary {
      gallery_position
      text_title
      text {
        raw
      }
    }
    items {
      gallery_image {
        alt
        url
      }
    }
  }
`;

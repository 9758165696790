import React from "react";

const FacebookShare = ({ fill = "#FF5CCD" }) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon--facebook-share"
      onMouseEnter={() => {
          
      }}
      onMouseLeave={() => {

      }}
    >
      <line className="icon-circle" x1="40" y1="40" x2="40" y2="40" stroke={fill} stroke-linecap="round" stroke-linejoin="round" stroke-width="66.6666" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.8584 28.2631C47.8195 28.0553 46.4164 27.9001 45.5339 27.9001C43.1445 27.9001 42.9893 28.939 42.9893 30.6012V33.5602H48.9623L48.4416 39.6896H42.9893V58.3334H35.5105V39.6896H31.6667V33.5602H35.5105V29.7689C35.5105 24.5756 37.9513 21.6667 44.0795 21.6667C46.2086 21.6667 47.767 21.9784 49.7922 22.394L48.8584 28.2631Z"
        fill="#700650"
      />
    </svg>
  );
};

export default FacebookShare;

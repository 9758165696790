import React, { useState, useRef } from "react";
import OutsideAlerter from "../../utils/DetectOutsideClick.js";
import { ArrowDown } from "../Icons/MadLogos";
import "./dropdown.scss";

export const Dropdown = ({
  currentActive,
  itemsArr,
  direction,
  callback = () => {},
}) => {
  const [showItems, setShowItems] = useState(false);
  const [selectedItem, setSelectedItem] = useState(itemsArr[0]);
  const parent = useRef();
  const selection = useRef();
  const child = useRef();

  return (
    <OutsideAlerter
      callback={() => {
        setShowItems(false);
      }}
    >
      <div
        className={`component-dropdown ${showItems ? "open" : "closed"}`}
        ref={parent}
        onMouseLeave={() => {
          setShowItems(false);
        }}
      >
        <div
          className={`component-dropdown__title ${direction || ""}`}
          onClick={() => {
            setShowItems(!showItems);
          }}
          onKeyDown={() => {
            setShowItems(!showItems);
          }}
          onMouseEnter={() => {
            setShowItems(true);
          }}
          role="button"
          tabIndex={0}
          ref={selection}
        >
          {itemsArr[0].value}
          <span>
            <ArrowDown />
          </span>
        </div>
        <div
          style={{
            top: selection.current?.offsetHeight,
            visibility: showItems ? "visible" : "hidden",
            pointerEvents: showItems ? "all" : "none",
          }}
          ref={child}
          className={`component-dropdown__item-wrapper ${direction || ""}`}
        >
          <div>
            {itemsArr.map((item, idx) =>
              idx > 0 ? (
                <div
                  key={item.id}
                  onClick={() => {
                    setSelectedItem(item);
                    callback(item.id);
                    // setShowItems(false);
                  }}
                  onKeyDown={() => {
                    setSelectedItem(item);
                    callback(item.id);
                    // setShowItems(false);
                  }}
                  role="button"
                  tabIndex={0}
                  className={`component-dropdown__item ${
                    selectedItem.value === item.value ||
                    (currentActive &&
                      currentActive.toUpperCase() === item.value)
                      ? "active"
                      : ""
                  }`}
                >
                  {item.value}
                </div>
              ) : null
            )}
            <div className="component-dropdown__item noAction"></div>
          </div>
        </div>
      </div>
    </OutsideAlerter>
  );
};

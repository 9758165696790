// Header.js file

import React, { useContext, useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Link from "../Link/Link.js";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Logo from "../Icons/Logo.js";
import { ThemeContext } from "../Layout";
import { ArrowBack } from "../Icons/MadLogos.js";
import useScrollListener from "../../hooks/useScrollListener";
import "./header.scss";

export const Header = React.forwardRef(
  ({ isHomepage, isProject, backTo, backLinkState = false }, ref) => {
    const queryData = useStaticQuery(graphql`
      {
        prismicNavigation {
          data {
            top_navigation {
              link_label {
                text
              }
              link_to
            }
            download_button_label
            download_button {
              url
            }
          }
        }
      }
    `);

    const navigation = queryData.prismicNavigation;
    const topNav = navigation.data.top_navigation;
    const scroll = useScrollListener();
    const [navClassList, setNavClassList] = useState([
      isHomepage ? "header--hidden" : "",
    ]);
    // const [navClassList, setNavClassList] = useState(["header--visible"]);
    const { colorP13, colorS10, colorFont } = useContext(ThemeContext);

    let logoColor = colorS10;

    if (isHomepage) logoColor = colorFont;
    if (isProject) logoColor = colorP13;

    useEffect(() => {
      if (isHomepage) {
        const _classList = [];
        if (scroll.y < 150) {
          _classList.push("header--hidden");
        } else {
          _classList.push("header--visible");
        }

        setNavClassList(_classList);
      }
    }, [scroll.y, scroll.lastY, isHomepage]);

    const backElement = () => {
      let backLink = (
        <AnchorLink to={backTo || "/"}>
          <ArrowBack fill="white" />
        </AnchorLink>
      );

      if (backLinkState) {
        backLink = (
          <Link to={backTo || "/"} state={backLinkState}>
            <ArrowBack fill="white" />
          </Link>
        );
      }

      return backLink;
    };

    return (
      <header
        className={`header ${isHomepage ? "homepage" : ""} ${
          isProject ? "project" : ""
        } ${navClassList.join(" ")}`}
        ref={ref ? ref : null}
      >
        {!isHomepage && (
          <div key="link-back" className="item-back">
            {backElement()}
          </div>
        )}
        <nav className="header__nav">
          <ul className="header__nav--items">
            <li key="link-home" className="item-home">
              <Link to={"/"}>
                <Logo fill={logoColor} />
              </Link>
            </li>
            {isHomepage && (
              <>
                {topNav.map((navItem, index) => {
                  return (
                    <li key={`link-${index}`} className="item-inner">
                      <AnchorLink
                        to={`/${
                          navItem.link_to ? "#".concat(navItem.link_to) : ""
                        }`}
                      >
                        {navItem.link_label.text}
                      </AnchorLink>
                    </li>
                  );
                })}
                <li key="link-download" className="item-button">
                  <a
                    role="button"
                    href={navigation.data.download_button?.url || ""}
                    target="_blank"
                    rel="noreferrer"
                    download="M.AD DECK"
                  >
                    {navigation.data.download_button_label || "download"}
                  </a>
                </li>
              </>
            )}
          </ul>
        </nav>
        {!isHomepage && <div className="item-hidden-block"></div>}
      </header>
    );
  }
);

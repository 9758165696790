import React from "react";

const Close = ({ className = "", onClick = () => {} }) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon--close ${className}`}
      onClick={onClick}
    >
      <path
        d="M7.5761 3.3594L6.64091 2.42463L5.00024 4.06483L3.35957 2.42463L2.42438 3.3594L4.06504 5L2.42438 6.6402L3.35957 7.57536L5.00024 5.93516L6.64091 7.57576L7.5761 6.6406L5.93544 4.9996L7.5761 3.3594ZM5.0002 10C2.23879 10 0 7.7613 0 4.9996C0 2.2387 2.23879 0 5.0002 0C7.76161 0 10 2.2387 10 4.9996C10.0004 7.7613 7.76201 10 5.0002 10Z"
        fill="#FF5CCD"
      />
    </svg>
  );
};

export default Close;

import React, { useState, useEffect, useRef, useCallback } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { RichText } from "prismic-reactjs";
import { spliceArrayN } from "../../utils/SpliceArrayN";
import { Dropdown } from "../../components/Dropdown/Dropdown.js";
import { Card } from "../../components/Card/Card.js";
import "./placeholderProject.scss";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

const BASE_URL = "/".concat(
  process.env.FUNCTIONS,
  "/",
  process.env.FUNCTION_PROJECT_STATS,
  "/"
);
const GET_URL = BASE_URL.concat("all");
const UPDATE_URL = BASE_URL.concat("update");

export const PlaceholderProject = ({ location, slice }) => {
  const queryData = useStaticQuery(graphql`
    query AllProjectsQuery {
      allPrismicCategory(sort: { fields: data___display_order, order: ASC }) {
        nodes {
          data {
            display_order
            title
          }
        }
      }
      normalOrder: allPrismicProject(
        filter: { data: { isjoker: { ne: true } } }
      ) {
        nodes {
          uid
          url
          data {
            category1
            category2
            category4
            category3
            category5
            year_of_creation
            client
            category
            isjoker
            card_image {
              url
              alt
            }
            title {
              text
            }
            description {
              raw
            }
          }
        }
      }
      prismicProject(data: { isjoker: { eq: true } }) {
        data {
          category1
          category2
          category3
          category4
          category5
          year_of_creation
          client
          category
          card_image {
            url
            alt
          }
          title {
            text
          }
          isjoker
          description {
            raw
          }
          joker_text {
            raw
          }
          joker_title
          button_text
          button_target {
            target
            url
          }
          background_image {
            url
          }
        }
        url
        uid
      }
    }
  `);

  const windowGlobal = typeof window !== "undefined" && window;

  const row =
    location.state && location.state.hasOwnProperty("row")
      ? location.state.row
      : false;
  const col =
    location.state && location.state.hasOwnProperty("col")
      ? location.state.col
      : false;

  if (windowGlobal) window.history.replaceState({}, "");

  const [currentSelectionCategory, setCurrentSelectionCategory] = useState(
    windowGlobal && localStorage.getItem("card-order-category") === null
      ? "Category"
      : windowGlobal && localStorage.getItem("card-order-category")
  );
  const [currentOrderDirection, setCurrentOrderDirection] = useState(
    windowGlobal && localStorage.getItem("card-order-direction") === null
      ? "DESC"
      : windowGlobal && localStorage.getItem("card-order-direction")
  );
  const [originalProjectsArray] = useState(
    JSON.parse(JSON.stringify(queryData.normalOrder.nodes))
  );
  const [renderArray, setRenderArray] = useState(
    windowGlobal && localStorage.getItem("card-order-array") === null
      ? [spliceArrayN([...originalProjectsArray], 4)]
      : windowGlobal && JSON.parse(localStorage.getItem("card-order-array"))
  );
  const [projectStats, setProjectStats] = useState(false);
  const [isUnloading, setIsUnloading] = useState(false);
  const swiperRefs = useRef({});
  const activeRow = useRef(false);

  const jokerProject = queryData.prismicProject;

  const categories = queryData.allPrismicCategory.nodes.map((category) => {
    return category.data.title;
  });
  categories.push("Year");
  categories.push("Likes");
  categories.push("Shares");

  const handleVisibilityChange = useCallback(
    (event) => {
      const sendRequest = async () => {
        const projectStats =
          windowGlobal && localStorage.getItem("project-stats") !== null
            ? JSON.parse(windowGlobal && localStorage.getItem("project-stats"))
            : false;
        if (projectStats) {
          Object.keys(projectStats).forEach((projectKey) => {
            if (projectStats[projectKey].likes === true) {
              windowGlobal && localStorage.setItem(projectKey, "set");
            }
          });
          const json = projectStats
            ? JSON.stringify({ batchUpdate: projectStats })
            : false;

          if (json) {
            windowGlobal && localStorage.removeItem("project-stats");
            await fetch(UPDATE_URL, {
              method: "POST",
              body: json,
            });
          }
        }
      };

      const fetchData = async () => {
        const projectStatsStream = await fetch(GET_URL);
        const tmpProjectStats = await projectStatsStream.json();
        setProjectStats(tmpProjectStats);
      };

      if (event.type === "visibilitychange") {
        if (event.target.visibilityState !== "hidden") {
          fetchData();
        } else {
          if (isUnloading) {
            return null;
          } else {
            sendRequest();
          }
        }
      }
    },
    [isUnloading, windowGlobal]
  );

  const handleEnd = useCallback(
    (event) => {
      setIsUnloading(true);
      const sendRequest = async () => {
        const projectStats =
          windowGlobal && localStorage.getItem("project-stats") !== null
            ? JSON.parse(windowGlobal && localStorage.getItem("project-stats"))
            : false;
        if (projectStats) {
          Object.keys(projectStats).forEach((projectKey) => {
            if (projectStats[projectKey].likes === true) {
              windowGlobal && localStorage.setItem(projectKey, "set");
            }
          });
          const json = projectStats
            ? JSON.stringify({ batchUpdate: projectStats })
            : false;

          if (json) {
            windowGlobal && localStorage.removeItem("project-stats");
            await fetch(UPDATE_URL, {
              method: "POST",
              body: json,
            });
          }
        }
      };

      sendRequest();
    },
    [windowGlobal]
  );

  useEffect(() => {
    async function fetchData() {
      const projectStatsStream = await fetch(GET_URL);
      const tmpProjectStats = await projectStatsStream.json();
      setProjectStats(tmpProjectStats);
    }

    fetchData();

    window.addEventListener("beforeunload", handleEnd);
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("unload", handleEnd);
    return () => {
      window.removeEventListener("beforeunload", handleEnd);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("unload", handleEnd);
      handleEnd();
    };
  }, [handleEnd, handleVisibilityChange]);

  useEffect(() => {
    if (typeof row === "number" && activeRow.current) {
      activeRow.current.scrollIntoView({ behavior: "auto", block: "center" });
    }
  }, [activeRow, row]);

  useEffect(() => {
    if (renderArray && windowGlobal) {
      localStorage.setItem("card-order-array", JSON.stringify(renderArray));
    }
  }, [renderArray, windowGlobal]);

  useEffect(() => {
    if (projectStats) {
      if (windowGlobal) {
        localStorage.setItem("project-stats", JSON.stringify(projectStats));
      }

      for (let i = 0; i < originalProjectsArray.length + 1; i++) {
        const curProject =
          i < originalProjectsArray.length
            ? originalProjectsArray[i].data
            : jokerProject.data;

        const uid =
          i < originalProjectsArray.length
            ? originalProjectsArray[i].uid
            : jokerProject.uid;

        curProject["categories"] = [];
        categories.forEach((category, idx) => {
          let numRating = 0;
          switch (category) {
            case "Year":
              numRating = parseInt(curProject["year_of_creation"]);
              break;
            case "Likes":
              if (projectStats[uid]) {
                const likes = parseInt(projectStats[uid].likes);
                numRating = likes ? likes : 0;
              }
              break;
            case "Shares":
              if (projectStats[uid]) {
                const shares = parseInt(projectStats[uid].shares);
                numRating = shares ? shares : 0;
              }
              break;
            default:
              numRating = parseInt(curProject[`category${idx + 1}`]);
              break;
          }

          curProject["categories"].push({
            title: category,
            rating: Number.isNaN(numRating) ? 0 : numRating,
          });
        });
      }

      const tmpCopyArr = JSON.parse(JSON.stringify(originalProjectsArray));
      setRenderArray(spliceArrayN(tmpCopyArr, 4));
      sortProjectsByCategory(
        currentSelectionCategory,
        currentOrderDirection === "DESC"
      );
    }
  }, [projectStats]);

  const sortProjectsByCategory = (category, desc) => {
    if (!(category === "Category")) {
      const tmpArr = JSON.parse(JSON.stringify(originalProjectsArray));
      tmpArr.sort(function (a, b) {
        let index = null;
        a.data.categories.forEach((cat, idx) => {
          if (cat.title === category) {
            index = idx;
          } else {
            return null;
          }
        });

        const val =
          Number(b.data.categories[index].rating) -
          Number(a.data.categories[index].rating);
        return !desc ? val * -1 : val;
      });
      setRenderArray(spliceArrayN(tmpArr, 4));
    } else {
      const tmpArr = JSON.parse(JSON.stringify(originalProjectsArray));
      setRenderArray(spliceArrayN(!desc ? tmpArr.reverse() : tmpArr, 4));
    }
  };

  return (
    <>
      <section
        className="slice-placeholder-project typo__section"
        id="projects"
      >
        <div className="typo__wrapper">
          <h2 className="typo__title">
            {RichText.asText(slice.primary.headline.raw)}
          </h2>
          <p className="typo__text">
            {RichText.asText(slice.primary.text.raw)}
          </p>
        </div>
        <div>
          <div className="slice-placeholder-project__dropdown-wrapper">
            <Dropdown
              direction="left"
              currentActive={currentSelectionCategory}
              itemsArr={[{ value: "Category", id: "Category" }].concat(
                categories.map((category, idx) => {
                  return { value: category.toUpperCase(), id: category };
                })
              )}
              callback={(id) => {
                windowGlobal && localStorage.setItem("card-order-category", id);
                sortProjectsByCategory(id, currentOrderDirection === "DESC");
                setCurrentSelectionCategory(id);
              }}
              size={320}
            />
            <Dropdown
              direction="right"
              currentActive={currentOrderDirection}
              itemsArr={[
                { value: "Sort by", id: "0" },
                { value: "DESC", id: "DESC" },
                { value: "ASC", id: "ASC" },
              ]}
              callback={(id) => {
                windowGlobal &&
                  localStorage.setItem("card-order-direction", id);
                sortProjectsByCategory(currentSelectionCategory, id === "DESC");
                setCurrentOrderDirection(id);
              }}
              size={320}
            />
          </div>
        </div>

        {renderArray &&
          renderArray.map((projectArr, rowIdx) => {
            return (
              <div
                className={`slice-placeholder-project__card-wrapper`}
                key={"palceholder-".concat(rowIdx)}
                id={`ProjectCardRow-${rowIdx}`}
                ref={(el) => {
                  if (typeof row === "number" && row === rowIdx) {
                    activeRow.current = el;
                  }
                }}
              >
                <Swiper
                  spaceBetween={30}
                  slidesPerView={"auto"}
                  autoHeight={true}
                  centeredSlides={true}
                  initialSlide={0}
                  onSlideChange={(swiper) => {
                    swiper.slides[swiper.previousIndex]
                      .querySelector(".component-card")
                      .classList.remove("active-card");
                  }}
                  onSwiper={(swiper) => {
                    swiperRefs.current[rowIdx] = swiper;

                    if (
                      typeof row === "number" &&
                      typeof col === "number" &&
                      rowIdx === renderArray.length - 1
                    ) {
                      if (swiperRefs.current[row]) {
                        swiperRefs.current[row].slideTo(col);
                      } else {
                        swiper.slideTo(col);
                      }
                    }
                  }}
                  breakpoints={{
                    768: {
                      centeredSlides: false,
                      spaceBetween: 48,
                    },
                  }}
                >
                  {projectArr.map((project, colIdx) => {
                    const description = project.data?.description.raw
                      ? RichText.asText(project.data.description.raw)
                      : "";
                    const attr = project.data
                      ? {
                          uniqueId: project.uid,
                          title: project.data.title,
                          client: project.data.client,
                          category: project.data.category,
                          year: project.data.year_of_creation,
                          image: project.data.card_image,
                          text:
                            description.length > 120
                              ? description.substring(0, 120).concat("...")
                              : description,
                          linkUrl: "/projects/".concat(project.uid),
                          categories: project.data.categories,
                          stats:
                            projectStats &&
                            projectStats.hasOwnProperty(project.uid)
                              ? projectStats[project.uid]
                              : { likes: false, shares: false },
                          callback: (obj) => {
                            if (projectStats) {
                              const copyProjectStats = { ...projectStats };
                              copyProjectStats[project.uid] = {
                                ...obj,
                              };
                              setProjectStats(copyProjectStats);
                            }
                          },
                          allowEdit:
                            windowGlobal &&
                            localStorage.getItem(project.uid) === null &&
                            projectStats !== false
                              ? true
                              : false,
                        }
                      : {};

                    return (
                      <SwiperSlide key={`slide-card-${colIdx}`}>
                        <Card
                          cardType="Project"
                          key={`card-${colIdx}`}
                          idx={4 * rowIdx + colIdx}
                          delay={(rowIdx + colIdx) * 100}
                          sortedArray={[...renderArray.flat(), jokerProject]}
                          {...attr}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            );
          })}
      </section>
      <section
        className="slice-placeholder-project-joker fullwidth"
        id="joker"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${jokerProject.data.background_image.url})`,
        }}
      >
        <div className="slice-placeholder-project-joker__wrapper">
          <div className="slice-placeholder-project-joker__column">
            <Card
              cardType="Joker"
              url={jokerProject.url}
              {...jokerProject.data}
              stats={
                projectStats && projectStats.hasOwnProperty(jokerProject.uid)
                  ? projectStats[jokerProject.uid]
                  : { likes: false, shares: false }
              }
              callback={(obj) => {
                if (projectStats) {
                  const copyProjectStats = { ...projectStats };
                  copyProjectStats[jokerProject.uid] = {
                    ...obj,
                  };
                  setProjectStats(copyProjectStats);
                }
              }}
              allowEdit={
                windowGlobal &&
                localStorage.getItem(jokerProject.uid) === null &&
                projectStats !== false
                  ? true
                  : false
              }
              idx={
                renderArray && renderArray.length > 0
                  ? renderArray.flat().length
                  : 0
              }
              sortedArray={
                renderArray && renderArray.length > 0
                  ? [...renderArray.flat(), jokerProject]
                  : []
              }
            />
          </div>
          <div className="slice-placeholder-project-joker__column wide">
            <div className="slice-placeholder-project-joker__column--title">
              {jokerProject.data.joker_title || ""}
            </div>
            <div className="slice-placeholder-project-joker__column--text">
              {RichText.asText(jokerProject.data.joker_text.raw)}
            </div>
            <a
              className="slice-placeholder-project-joker__column--button"
              href={jokerProject.data.button_target.url || "/"}
              target="_blank"
              rel="noreferrer"
            >
              {jokerProject.data.button_text || ""}
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export const query = graphql`
  fragment HomepageDataBodyPlaceholder on PrismicHomepageDataBodyPlaceholder {
    primary {
      key
      headline {
        raw
      }
      text {
        raw
      }
    }
  }
`;
